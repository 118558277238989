import { motion } from 'framer-motion';
import { elementVariantEarly } from '../helpers/animationVariants';
import { AwesomeButton } from 'react-awesome-button';
import { BanknotesIcon, RocketLaunchIcon } from '@heroicons/react/24/outline';

export default function Customers() {
    return (
        <div className="isolate overflow-hidden bg-white pt-8 pb-16">
            <div className="mx-auto max-w-2xl px-4">
                {/* Animated heading */}
                <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={elementVariantEarly}
                    viewport={{ once: true, amount: 0 }}
                    className="font-unbounded"
                >
                    <p className="pt-8 text-base text-vivid-cyan font-semibold">OUR PRIMARY CUSTOMERS</p>
                    <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900">
                        Is Big Web for you? <span className="text-gray-500">Probably.</span>
                    </p>
                </motion.div>

                {/* Cards */}
                <div className="relative shadow-2xl mt-8 px-4 pt-4 pb-8 border-[1px] border-gray-400 rounded-3xl font-poppins grid sm:grid-cols-2 gap-y-12">
                    {/* Vertical line */}
                    <div className="hidden sm:block absolute left-1/2 top-0 transform -translate-x-1/2 h-full w-px bg-gray-400"></div>

                    {/* Card 1: Growing Businesses */}
                    <div className="pr-4">
                        {/* Icon */}
                        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-blue-50">
                            <BanknotesIcon className="h-6 w-6 text-vivid-cyan" />
                        </div>

                        {/* Title */}
                        <h3 className="mt-4 text-lg font-semibold text-gray-900">Growing Businesses</h3>

                        {/* Description */}
                        <p className="mt-2 text-sm text-gray-600">
                            Get the website, branding, and marketing you need to scale...without the hassle of hiring in-house. We design, refine, and optimize so you can focus on what you love.
                        </p>

                        {/* CTA */}
                        <div className="mt-4">
                            <AwesomeButton type="secondary" ripple>
                                View plans
                            </AwesomeButton>
                        </div>
                    </div>

                    {/* Card 2: Nonprofits & Organizations */}
                    <div className="sm:pl-4">
                        {/* Icon */}
                        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-blue-50">
                            <RocketLaunchIcon className="h-6 w-6 text-vivid-cyan" />
                        </div>

                        {/* Title */}
                        <h3 className="mt-4 text-lg font-semibold text-gray-900">Multi Suite Enterprises</h3>

                        {/* Description */}
                        <p className="mt-2 text-sm text-gray-600">
                            Struggling to manage dozens of sites? We work like your in-house team...revamping, building, and handling unlimited requests through HoneyBook with a 48-hour turnaround.
                        </p>

                        {/* CTA */}
                        <div className="mt-4">
                            <AwesomeButton type="secondary" ripple>
                                Contact us
                            </AwesomeButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}