import { useState } from 'react';
import highlightedWorksData from '../data/highlights.json';
import workData from '../data/works.json';

// components
import '../global.css';
import ParticlesBackground from "../components/ParticlesBackground";
import HighlightedWorks from '../components/HighlightedWorks';
import MonetModal from '../components/MonetModal';
import GetTraindModal from '../components/GetTraindModal';
import Navigation from '../components/Navigation';
import FooterCentered from '../components/FooterCentered';

// libraries
import { motion } from "framer-motion";

// data
const highlightedWorks = highlightedWorksData.map((highlightedWork) => ({
    ...highlightedWork,
    images: highlightedWork.images.map((img) => require(`../media/${img}`))
}));

const works = workData.map((work) => ({
    ...work,
    imageSrc: require(`../media/${work.imageSrc}`),
}));

export default function Works() {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [selectedWork, setSelectedWork] = useState(null);

    const handleEmail = (e) => {
        e.preventDefault();
        console.log(email);
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <ParticlesBackground id="tsparticles" />
            <div className="bg-white">
                <Navigation />

                {/* Featured Works Section */}
                <HighlightedWorks works={highlightedWorks} />

                <div className="font-unbounded max-w-2xl mx-auto px-6 lg:px-8 pt-8">
                    <p className="text-sm text-vivid-cyan font-semibold">THATS NOT ALL</p>
                    <h2 className="text-2xl font-bold text-gray-800 pt-4">Wait, <span className="text-gray-500">there&apos;s more</span></h2>
                </div>

                {/* Grid gallery of client logos */}
                <div className="mx-auto max-w-3xl px-1">
                    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4">
                        {works.map((work) => (
                            <div
                                key={work.id}
                                className="group relative flex flex-col overflow-hidden bg-white transition duration-500 ease-in-out transform hover:-translate-y-1"
                                onClick={() => {
                                    setSelectedWork(work);
                                    setOpen(true);
                                }}
                            >
                                <div className="aspect-h-3 aspect-w-3 sm:aspect-none group-hover:opacity-75 sm:h-auto">
                                    <a href={work.href} target="_blank" rel="noopener noreferrer">
                                        <img
                                            src={work.imageSrc} // replaced work.images[0] with work.imageSrc
                                            alt={work.name}
                                            className="h-full w-full mx-auto sm:h-full sm:w-full"
                                        />
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <MonetModal
                    open={selectedWork?.name === 'Monet.ai'}
                    setOpen={setSelectedWork}
                    email={email}
                    setEmail={setEmail}
                    handleEmail={handleEmail}
                />
                <GetTraindModal
                    open={open && selectedWork && selectedWork.name === 'GetTraind'}
                    setOpen={setOpen}
                    selectedWork={selectedWork}
                />

            </div>
            <FooterCentered />
            <div className="mb-32"></div>
        </motion.div>
    );
}
