import {
    LightBulbIcon,
    ServerIcon,
} from '@heroicons/react/20/solid';
import { UsersIcon, ArrowDownOnSquareStackIcon, ArrowPathIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline';
import servicesData from '../data/services.json';
import { elementVariantEarly } from '../helpers/animationVariants';

//libraries
import { motion } from "framer-motion";

const icons = {
    UsersIcon,
    ArrowDownOnSquareStackIcon,
    ArrowPathIcon,
    ArrowTrendingUpIcon,
    LightBulbIcon,
    ServerIcon,
};

const services = servicesData.map((service) => ({
    ...service,
    icon: icons[service.icon],
}));

export default function Services() {
    return (
        <div className="relative isolate bg-white pt-12 pb-8">
            <div className="mx-auto max-w-2xl px-4">
                <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={elementVariantEarly}
                    viewport={{ once: true, amount: 0 }}
                    className="font-unbounded"
                >
                    <p className="pt-12 text-base text-vivid-cyan font-semibold">
                        HOW IT WORKS
                    </p>
                    <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900">Simple Process, <span className="text-gray-500">better results</span></p>
                </motion.div>
                <div className="mt-4 font-poppins">
                    <dl className="grid grid-cols-1 gap-6 text-sm text-gray-600 sm:grid-cols-2">
                        {services.map((service) => (
                            <motion.div
                                key={service.name}
                                className="border-[1px] border-gray-400 rounded-lg"
                                initial="offscreen"
                                whileInView="onscreen"
                                variants={elementVariantEarly}
                                viewport={{ once: true, amount: 0 }}
                            >
                                <dt className={`w-12 h-12 flex items-center justify-center mt-4 mx-4 mb-2 rounded-full ${service.background}`}>
                                    <service.icon className={`h-7 w-7 ${service.iconColor}`} aria-hidden="true" />
                                </dt>
                                <dd className="mx-4 pb-6">
                                    <span className={`font-bold ${service.textColor}`}>
                                        {service.name}
                                    </span>{' '}
                                    <span>
                                        {service.description}
                                    </span>
                                </dd>
                            </motion.div>
                        ))}
                    </dl>
                </div>
            </div>

        </div>
    )
}
