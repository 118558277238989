import LinkedInIcon from './LinkedInIcon';

const navigation = {
  main: [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/#about' },
    { name: 'Clients', href: '/works' },
  ],
  secondary: [
    { name: 'Terms', href: '/terms' },
    { name: 'Privacy', href: '/privacy' },
  ],
}

export default function FooterCentered() {
  return (
    <footer>
      <div className="pt-16 bg-white xl:bg-transparent">
        <div className='flex justify-between mx-auto w-full lg:w-2/4 px-4 lg:px-0'>
          <nav className="flex gap-x-4 sm:gap-x-6" aria-label="Footer">
            {navigation.main.map((item) => (
              <div key={item.name} className="pb-2">
                <a href={item.href} className="text-sm leading-6 text-gray-600 hover:no-underline hover:text-bright-magenta">
                  {item.name}
                </a>
              </div>
            ))}
          </nav>
          <div>
            <a
              href="https://www.linkedin.com/company/big-web-labs"
              target="_blank"
              rel="noopener noreferrer"
              className="text-vivid-cyan hover:text-bright-magenta"
            >
              <LinkedInIcon className="h-6 w-6" />
            </a>
          </div>
        </div>
        <div className="relative py-2 lg:py-0">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="mx-4 lg:mx-auto w-full lg:w-2/4 border-t border-gray-400" />
          </div>
        </div>
      </div>
      <div className="hidden justify-between mx-auto w-full lg:w-2/4 overflow-hidden pt-2 px-4 lg:px-0 bg-white xl:bg-transparent">
        <p className="text-sm leading-5 text-gray-500">
          &copy; Till time ends. Big Web Labs. All rights reserved.
        </p>
        <nav className="text-center justify-center flex sm:justify-center gap-x-4 sm:gap-x-6" aria-label="Footer">
          {navigation.secondary.map((item) => (
            <div key={item.name} className="pb-6">
              <a href={item.href} className="text-sm leading-6 text-gray-600 hover:no-underline hover:text-bright-magenta">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
      </div>
      <div className="lg:flex text-center justify-between mx-auto w-full lg:w-2/4 overflow-hidden pt-2 px-2 lg:px-0 bg-white xl:bg-transparent">
        <p className="text-sm leading-5 text-gray-500">
          &copy; Till time ends. Big Web Labs. All rights reserved.
        </p>
        <nav className="text-center justify-center flex sm:justify-center gap-x-4 sm:gap-x-6" aria-label="Footer">
          {navigation.secondary.map((item) => (
            <div key={item.name} className="pb-6">
              <a href={item.href} className="text-sm leading-6 text-gray-600 hover:no-underline hover:text-bright-magenta">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
      </div>
    </footer>
  )
}
