// OneTimeProject.jsx
import React from 'react';
import { AwesomeButton } from 'react-awesome-button';
import { elementVariantEarly } from '../helpers/animationVariants';

//libraries
import { motion } from 'framer-motion';

export default function OneTimeProject() {
    return (
        <div className="flex items-center justify-center">
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                variants={elementVariantEarly}
                viewport={{ once: true, amount: 0 }}
                className="my-10 max-w-3xl mx-2 rounded-3xl bg-gradient-to-r from-[#1AA8D7] to-[#A4E9FF] p-8"
            >
                <div className="flex flex-col items-start justify-between sm:flex-row sm:items-center">
                    {/* Left Text Content */}
                    <div className="w-full sm:w-[80%]">
                        <h3 className="text-lg font-bold text-white font-unbounded">
                            Have a one-time project? No problem.
                        </h3>
                        <p className="mt-2 text-sm text-white sm:text-base font-poppins">
                            If your project doesn't fit any of our subscriptions and you'd prefer a one-time fee,
                            book a free intro call!
                        </p>
                    </div>

                    {/* Book a Call Button */}
                    <div className='mt-4 sm:mt-0'>
                        <AwesomeButton
                            type="secondary"
                            ripple
                            href="https://outlook.office365.com/book/consultations@bigweblabs.com/"
                            target="_blank"
                            className="font-popins"
                        >
                            Book a call
                        </AwesomeButton>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}