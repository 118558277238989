// Pricing.jsx
import { useState } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { AwesomeButton } from 'react-awesome-button';
import { elementVariantEarly } from '../helpers/animationVariants';

//libraries
import { motion } from 'framer-motion';

export default function Pricing() {
    const [billingCycle, setBillingCycle] = useState('quarterly') // 'monthly' or 'quarterly'
    const [aiAgent, setAiAgent] = useState(false)

    // Base prices
    const monthlyPrice = 6890
    const quarterlyPrice = 5890
    const aiAgentPrice = 1490

    // Calculate final price
    const basePrice = billingCycle === 'monthly' ? monthlyPrice : quarterlyPrice
    const finalPrice = basePrice + (aiAgent ? aiAgentPrice : 0)

    return (
        <div className="isolate overflow-hidden bg-white pt-8 pb-16">
            <div className="mx-auto max-w-2xl px-4 lg:px-6">
                <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={elementVariantEarly}
                    viewport={{ once: true, amount: 0 }}
                    className="font-unbounded"
                >
                    <p className="pt-8 text-base text-vivid-cyan font-semibold">
                        OUR PLANS
                    </p>
                    <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900">Honest Pricing, <span className="text-gray-500">consistent quality</span></p>
                </motion.div>

                <div className="shadow-2xl mt-8 p-2 border-[1px] border-gray-400 rounded-3xl font-poppins">
                    {/* Pricing Card */}
                    <div className="rounded-3xl bg-blue-50 p-8 border-[1px] border-gray-400 space-y-6">

                        {/* Toggle for monthly/quarterly */}
                        <div className="flex justify-center">
                            <div className="flex space-x-2 bg-blue-100 p-2 rounded-xl shadow-[inset_0_4px_4px_-4px_rgba(0,0,0,0.5)] w-11/12">
                                {/* Monthly button */}
                                <button
                                    type="button"
                                    onClick={() => setBillingCycle('monthly')}
                                    className="relative w-full px-4 py-2 text-sm font-medium text-vivid-cyan rounded-lg"
                                >
                                    {billingCycle === 'monthly' && (
                                        <motion.div
                                            layoutId="billingBackground"
                                            className="absolute inset-0 bg-white rounded-lg shadow"
                                            initial={false}
                                            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                                        />
                                    )}
                                    <span className="relative z-10">Monthly</span>
                                </button>

                                {/* Quarterly button */}
                                <button
                                    type="button"
                                    onClick={() => setBillingCycle('quarterly')}
                                    className="relative w-full px-4 py-2 text-sm font-medium text-vivid-cyan rounded-lg"
                                >
                                    {billingCycle === 'quarterly' && (
                                        <motion.div
                                            layoutId="billingBackground"
                                            className="absolute inset-0 bg-white rounded-lg shadow"
                                            initial={false}
                                            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                                        />
                                    )}
                                    <div className="flex flex-col sm:flex-row items-center justify-center w-full">
                                        <span
                                            className={`hidden sm:inline order-1 sm:order-2 sm:ml-2 relative z-10 text-xs font-bold px-2 py-1 rounded-full ${billingCycle === 'quarterly'
                                                ? 'bg-red-100 text-red-600'
                                                : 'bg-gray-300 text-gray-400'
                                                }`}
                                        >
                                            Save $4,000
                                        </span>
                                        <span className="order-2 sm:order-1 relative z-10">
                                            Quarterly
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </div>

                        {/* Price */}
                        <div className="mt-1 text-center">
                            <span className="text-4xl sm:text-5xl font-bold tracking-tight text-gray-900">
                                ${finalPrice.toLocaleString()}
                            </span>
                            <span className="text-base font-semibold text-gray-600">
                                /month<br /><span className="text-xs mt-2 text-gray-500">Pause or cancel anytime</span>
                            </span>
                        </div>

                        {/* Book a call */}
                        <div className="mt-1 flex justify-center">
                            <AwesomeButton
                                type="secondary"
                                ripple
                                href="https://outlook.office365.com/book/consultations@bigweblabs.com/"
                                target="_blank"
                            >
                                Book a call
                            </AwesomeButton>
                        </div>
                    </div>

                    {/* AI Agent Add-on */}
                    <div className="mt-1 items-center rounded-3xl bg-gray-50 p-6 border-[1px] border-gray-400">
                        <div className="max-w-lg mx-auto">
                            <h3 className="text-md font-bold text-vivid-cyan font-unbounded">ADD-ON</h3>
                        </div>
                        <div className="flex mt-4 max-w-lg mx-auto">
                            <div>
                                <label htmlFor="aiAgentToggle" className="inline-flex items-center cursor-pointer">
                                    <input
                                        id="aiAgentToggle"
                                        type="checkbox"
                                        className="h-5 w-5 mr-4 rounded border-gray-300 text-vivid-cyan focus:ring-vivid-cyan"
                                        checked={aiAgent}
                                        onChange={() => setAiAgent(!aiAgent)}
                                    />
                                </label>
                            </div>
                            <div className="text-sm sm:text-md font-semibold text-gray-800">
                                AI Integrations
                                <span className="ml-2 text-sm text-gray-500">+${aiAgentPrice}</span>
                                <p className="mt-1 text-xs italic text-gray-400">
                                    Sales Flows, Marketing, Workflow Automations
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* What's in the package */}
                    <div className="mt-1 rounded-3xl bg-white p-6 w-full mx-auto">
                        <div className="max-w-lg mx-auto">
                            <h3 className="text-md font-bold text-vivid-cyan font-unbounded">INCLUDED IN PACKAGE</h3>
                            <ul className="mt-4 space-y-3 text-sm leading-6 text-gray-700">
                                {[
                                    'Web Apps',
                                    'Domain & Hosting',
                                    'Branding Kits',
                                    'Landing Pages',
                                    'Presentation Design',
                                    'UX/UI Design',
                                    'SEO Optimization',
                                ].map((feature) => (
                                    <li key={feature} className="flex items-start gap-x-3">
                                        <CheckIcon className="h-5 w-5 flex-none text-vivid-cyan" aria-hidden="true" />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    {/* Always included */}
                    <div className="mt-1 rounded-3xl bg-gray-50 p-6 border-[1px] border-gray-400">
                        <div className="max-w-lg mx-auto">
                            <h3 className="text-md font-bold text-vivid-cyan font-unbounded">ALWAYS INCLUDED</h3>
                            <ul className="mt-4 space-y-3 text-sm leading-6 text-gray-700">
                                {[
                                    'Unlimited Requests',
                                    'Unlimited Revisions',
                                    'Fast Turnaround',
                                    'No Hiring',
                                    'Pause or Cancel anytime',
                                ].map((benefit) => (
                                    <li key={benefit} className="flex items-start gap-x-3">
                                        <CheckIcon className="h-5 w-5 flex-none text-vivid-cyan" aria-hidden="true" />
                                        {benefit}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}