import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AnimatePresence } from "framer-motion";

import Stealth from './stealth/stealth';

//pages
import Home from './Pages/Home';
import Works from './Pages/Works';
import WorkDetail from './Pages/WorkDetail'; // <-- new import
import Contact from './Pages/Contact';
import Privacy from './Pages/Privacy';
import Terms from './Pages/Terms';
import About from './Pages/About';

//files
import './App.css';
import './global.css';

export default function App() {
  const location = useLocation();

  return (
    <div>
      <ToastContainer />
      <AnimatePresence mode="wait">
        <Routes key={location.pathname}>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="works" element={<Works />} />
            <Route path="works/:workName" element={<WorkDetail />} />
            <Route path="contact" element={<Contact />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="terms" element={<Terms />} />
            <Route path="*" element={<Stealth />} />
            <Route path="stealth" element={<Stealth />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </div>
  );
}
