import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { elementVariantEarly } from '../helpers/animationVariants';

//libraries
import { motion } from "framer-motion";

export default function HomepageHeroSection() {
    const [currentTime, setCurrentTime] = useState('');

    useEffect(() => {
        // Update time continuously in San Francisco time zone
        const timer = setInterval(() => {
            setCurrentTime(
                new Date().toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles' })
            );
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="relative isolate px-4 lg:px-8 bg-white pb-12">
            <div
                className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#a5dff0] to-[#a6e7ff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
            <div className="mx-auto max-w-3xl pt-28 sm:pt-36">
                <div className="text-center">
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        variants={elementVariantEarly}
                        viewport={{ once: true, amount: 0 }}
                        className="flex items-center justify-center gap-x-4 mt-6"
                    >
                        <div className="relative h-3 w-3">
                            <div className="absolute inset-0 rounded-full bg-vivid-cyan animate-ping opacity-75"></div>
                            <div className="absolute top-1/2 left-1/2 h-3 w-3 rounded-full bg-vivid-cyan transform -translate-x-1/2 -translate-y-1/2"></div>
                        </div>
                        <p className="font-poppins">
                            1 spot available - <Link href="/" className="font-semibold underline">Reserve Now</Link>
                        </p>
                    </motion.div>
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        variants={elementVariantEarly}
                        viewport={{ once: true, amount: 0 }}
                    >
                        <h1 className="my-6 text-4xl font-unbounded tracking-normal text-black sm:text-4xl">
                            Website, Brands, and Marketing<br />Built to Scale.
                        </h1>
                    </motion.div>
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        variants={elementVariantEarly}
                        viewport={{ once: true, amount: 0 }}
                    >
                        <p className="mt-6 text-sm max-w-xl mx-auto font-poppins text-gray-900">
                            Skip the hiring hassle. Get a website, with design, branding &amp; marketing, that more than works in 4 weeks or less.
                        </p>
                    </motion.div>
                </div>
            </div>
            {/* <div className="mx-auto max-w-3xl">
                <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={elementVariantEarly}
                    viewport={{ once: false, amount: 0 }}
                    className="justify-center"
                >
                    <SlidingLogoWall />
                </motion.div>
            </div> */}
            <div className="mx-auto max-w-2xl my-6">
                <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={elementVariantEarly}
                    viewport={{ once: true, amount: 0 }}
                >
                    <div className="relative">
                        <p className="flex items-center justify-center text-center text-base max-w-xl mx-auto font-poppins leading-8 text-black space-x-2">
                            <span className="block w-6 text-center">📍</span>
                            <span className="flex items-center space-x-1">
                                <span className="min-w-[6rem] text-center">San Francisco</span>
                                <span className="text-center">|</span>
                                <span className="min-w-[6rem] font-mono tabular-nums text-center">
                                    {currentTime}
                                </span>
                            </span>
                        </p>
                    </div>
                </motion.div>
            </div>
        </div>
    );
}