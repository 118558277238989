import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLongRightIcon } from "@heroicons/react/24/solid";
import { AwesomeButton } from 'react-awesome-button';


export default function HighlightedWorks({ works }) {
    return (
        <div className="mx-auto max-w-2xl px-6 lg:px-8 pt-28 sm:pt-36">
            {works.map((work) => (
                <div key={work.id} className="mb-16 mt-8 lg:mt-0">
                    <div className="font-unbounded">
                        <p className="text-sm text-vivid-cyan font-semibold">{work.workType}</p>
                        <h2 className="text-2xl font-bold text-gray-800 py-4">{work.name}</h2>
                    </div>
                    {/* Image section */}
                    <div className="flex flex-col items-center">
                        {/* Big image */}
                        {work.images && work.images.length > 0 && (
                            <img
                                src={work.images[0]}
                                alt={`${work.name} main`}
                                className=""
                            />
                        )}

                        {/* Smaller images (up to 4) */}
                        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-2 w-full">
                            {work.images &&
                                work.images.slice(1, 5).map((imgSrc, idx) => (
                                    <img
                                        key={idx}
                                        src={imgSrc}
                                        alt={`${work.name} thumbnail ${idx}`}
                                        className="mx-auto"
                                    />
                                ))}
                        </div>
                    </div>

                    <div className="font-unbounded mt-4">
                        <h2 className="text-2xl font-bold text-gray-800 py-4">{work.subheading}</h2>
                    </div>

                    {/* Text content */}
                    <div className="text-start">
                        <p className="text-gray-600 max-w-2xl mx-auto font-poppins text-sm whitespace-pre-line">
                            {work.description}
                        </p>

                        {/* CTA button */}
                        <div className="mt-8">
                            <Link to={`/works/${work.pageName}`}> {/* Changed link target */}
                                <AwesomeButton type="primary" ripple>
                                    Explore Work
                                    <ArrowLongRightIcon className="mx-1 h-auto w-7 text-gray-400" aria-hidden="true" />
                                </AwesomeButton>
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
