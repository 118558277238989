import React from 'react';

export default function LinkedInIcon({ className, ...props }) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="currentColor" 
            viewBox="0 0 24 24"
            className={className}
            {...props}
        >
            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11 19h-3v-10h3v10zm-1.5-11.268c-.966 0-1.75-.783-1.75-1.75s.784-1.75 1.75-1.75 1.75.783 1.75 1.75-.783 1.75-1.75 1.75zm13.5 11.268h-3v-5.604c0-1.337-.026-3.063-1.865-3.063-1.865 0-2.151 1.455-2.151 2.961v5.706h-3v-10h2.881v1.367h.041c.402-.762 1.386-1.565 2.849-1.565 3.044 0 3.606 2.005 3.606 4.613v5.585z"/>
        </svg>
    );
}
