//components
import ParticlesBackground from "../components/ParticlesBackground";
import Navigation from '../components/Navigation';
import HomepageHeroSection from '../components/HomepageHeroSection';
import FeaturedWork from '../components/FeaturedWork';
import DividerWithButton from '../components/DividerWithButtion';
import Services from '../components/Services';
import Customers from "../components/Customers";
import Pricing from '../components/Pricing';
import About from '../components/About';
import OneTimeProject from '../components/OneTimeProject';
import FooterCentered from '../components/FooterCentered';

//libraries
import { motion } from "framer-motion";

export default function Home() {

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <ParticlesBackground id="tsparticles" />
            <Navigation />
            <HomepageHeroSection />
            <FeaturedWork />
            <DividerWithButton />
            <Services />
            <Customers />
            <Pricing />
            <OneTimeProject />
            <About />
            <FooterCentered />
            <div className="mb-32"></div>
        </motion.div>
    )
}
