import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';
import { AwesomeButton } from 'react-awesome-button';

export default function DividerWithButton() {
  return (
    <div className="bg-white pt-8">
      <div className="relative max-w-2xl mx-auto px-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-[90%] mx-auto border-t border-gray-400" />
        </div>
        <div className="relative flex justify-center font-poppins">
          <Link to="/works">
            <AwesomeButton type="primary" ripple>
              <PlusIcon className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              View All Work
            </AwesomeButton>
          </Link>
          {/* <button
            type="button"
            className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <PlusIcon className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            <Link to="/works" className="hover:no-underline hover:text-bright-magenta">All Work</Link>
          </button> */}
        </div>
      </div>
    </div>
  )
}