import React from 'react';
import { elementVariantEarly } from '../helpers/animationVariants';
import featuredWorksData from '../data/featuredworks.json';
import { motion } from 'framer-motion';

const featuredworks = featuredWorksData.map((featuredwork) => ({
    ...featuredwork,
    videoSrc: featuredwork.videoSrc,
}));

export default function FeaturedWork() {

    return (
        <div className="relative isolate px-4 bg-white">
            <div className=" max-w-2xl mx-auto">
                <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={elementVariantEarly}
                    viewport={{ once: true, amount: 0 }}
                    className="font-unbounded pb-8"
                >
                    <p className="pt-8 text-base text-vivid-cyan font-semibold">
                        IN THE LAB
                    </p>
                    <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900">We're Building <span className="text-gray-500">SendMeFlowers.io</span></p>
                </motion.div>
                <div className="border-[1px] border-gray-400 rounded-[40px] bg-white bg-opacity-85 shadow-[0_0_20px_rgba(0,0,0,0.15)] hover:shadow-[0_0_20px_rgba(0,0,0,0.30)] hover:bg-opacity-100 overflow-hidden">
                    <div className="p-2">
                        {featuredworks.map((featuredwork) => (
                            <motion.div
                                key={featuredwork.id}
                                className="justify-between items-center relative cursor-pointer font-poppins"
                                initial="offscreen"
                                whileInView="onscreen"
                                variants={elementVariantEarly}
                                viewport={{ once: true, amount: 0 }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(featuredwork.href, '_blank', 'noopener,noreferrer');
                                }}
                            >
                                <div>
                                    <video
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        className="h-auto w-full mx-auto object-cover object-center rounded-[40px]"
                                    >
                                        <source
                                            src={featuredwork.videoSrc}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                {/* <div className="text-gray-600 px-8 py-4 space-y-2">
                                    <a href={featuredwork.href}></a>
                                    <h3 className="text-2xl text-vivid-cyan text-center">{featuredwork.name}</h3>
                                    <p>{featuredwork.description}</p>
                                </div> */}
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}