import { useState } from 'react';
import { elementVariantEarly } from '../helpers/animationVariants';

//libraries
import { motion, AnimatePresence } from 'framer-motion';

const teamMembers = [
    {
        name: "Sean",
        titleLine1: "8+ years experience in product & design",
        titleLine2: "M.S. Software engineering – Cybersecurity",
        imageUrl: "images/Sean.png",
        about: `Hey, I'm Sean. Born in Nigeria, raised in NYC,  I've called seven countries home across four continents. I'm a serial entrepreneur at heart who values people first. If you want to connect about tech, startups, or anything between, let's talk.
        
        My journey through Computer Science, Math, and Cybersecurity has led me to build solutions across supply chain, robotics, AI, and healthcare—always focused on solving real problems with emerging technology. I've found my purpose at the intersection of innovation and impact.
        
        We built Big Web Labs to push boundaries and deliver meaningful technology that transforms how people live and work. This isn't just about building products—it's about partnering with you to bring your vision to life exactly as you imagine it, with precision and care at every step. 🚀
      `,
    },
    {
        name: "JD",
        titleLine1: "10+ years in Full-stack Development",
        titleLine2: "Ph.D. Computer Science – AI & ML",
        imageUrl: "images/JD.png",
        about: `Hey, I’m JD. I was born in Xi’an, China, grew up in Seattle, and studied in the Bay Area. If you ever want to connect—talk business, sports, life, or anything else—I’m always down to grab a coffee. ☕
        
        My background spans design, venture capital, and entrepreneurship, with experience across AI, healthcare, fashion, nonprofits, and B2B SaaS. I’ve worked with startups and established brands alike, helping them turn ideas into tangible, high-impact brands, websites, and marketing strategies.
        
        We founded Big Web Labs to turn ideas into designs that don’t just look great but drive real results. This is your project, not ours, and we’re here to make sure every pixel, every line, and every detail tells your story the way it’s meant to be told. No ego, no fluff—just great execution.
      `,
    },
]

export default function About() {
    // Track which team member is currently displayed
    const [currentIndex, setCurrentIndex] = useState(0);
    const currentMember = teamMembers[currentIndex];

    return (
        <div id="about" className="relative isolate bg-white pt-12 pb-8 scroll-mt-0">
            <div className="mx-auto max-w-3xl px-4">
                <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={elementVariantEarly}
                    viewport={{ once: true, amount: 0 }}
                    className="font-unbounded"
                >
                    <p className="pt-4 text-base text-vivid-cyan font-semibold">
                        ABOUT
                    </p>
                    <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900">Who's Running The Lab 🧑‍💻</p>
                </motion.div>

                {/* Content Section */}
                <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    variants={elementVariantEarly}
                    viewport={{ once: true, amount: 0 }}
                    className="my-8 grid gap-6 md:grid-cols-2 font-poppins"
                >
                    {/* Left: Text area */}
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={currentMember.about} // use a key that changes when the member changes
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className="order-2 md:order-1 flex flex-col justify-center"
                        >
                            <p className="whitespace-pre-line text-sm leading-7 text-gray-700">
                                {currentMember.about}
                            </p>
                        </motion.div>
                    </AnimatePresence>

                    {/* Right: Image + Circle Indicators */}
                    <div className="order-1 md:order-2 flex flex-col items-center justify-center rounded-3xl shadow-[0_0_10px_rgba(0,0,0,0.15)] bg-white p-4">
                        <div className="rounded-3xl border border-vivid-cyan h-auto w-60 md:h-full md:w-full p-4">
                            <AnimatePresence mode="wait">
                                <motion.img
                                    key={currentMember.imageUrl} // ensure key changes on update
                                    src={currentMember.imageUrl}
                                    alt={currentMember.name}
                                    className="h-auto w-60 md:h-full md:w-full rounded-2xl object-cover"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                />
                            </AnimatePresence>
                        </div>

                        {/* Circle Indicators */}
                        <div className="mt-4 flex space-x-2">
                            {teamMembers.map((_, index) => (
                                <button
                                    key={index}
                                    onClick={() => setCurrentIndex(index)}
                                    className={`h-3 w-3 rounded-full transition-colors ${currentIndex === index ? 'bg-vivid-cyan' : 'bg-gray-300'
                                        }`}
                                />
                            ))}
                        </div>

                        {/* Name + Title */}
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={currentMember.name} // use a key that changes when the member changes
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                                className="w-full mt-4 md:mt-0"
                            >
                                <h3 className="text-2xl font-unbounded font-bold text-gray-900">
                                    {currentMember.name}
                                </h3>
                                <p className="mt-2 text-xs font-medium text-gray-500">
                                    {currentMember.titleLine1}
                                </p>
                                <p className="text-xs font-medium text-gray-500">
                                    {currentMember.titleLine2}
                                </p>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </motion.div>
            </div>

            <div
                className="absolute inset-x-0 top-[calc(100%-40rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-50rem)]"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#a5dff0] to-[#a6e7ff] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
        </div>
    )
}
