import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ParticlesBackground from "../components/ParticlesBackground";
import highlightedWorksData from '../data/highlights.json';
import Navigation from '../components/Navigation';
import MonetModal from '../components/MonetModal';
import FooterCentered from '../components/FooterCentered';
import { ArrowLongRightIcon } from "@heroicons/react/24/solid";
import { motion } from 'framer-motion';
import { AwesomeButton } from 'react-awesome-button';

export default function WorkDetail() {
    const { workName } = useParams();

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [selectedWork, setSelectedWork] = useState(null);

    const handleEmail = (e) => {
        e.preventDefault();
        console.log(email);
    }

    // Find the matching project by name (case-insensitive)
    const project = highlightedWorksData.find(
        (p) => p.pageName.toLowerCase() === workName.toLowerCase()
    );

    // Handle case: project not found
    if (!project) {
        return (
            <div className="max-w-2xl mx-auto py-8 px-4 pt-24">
                {/* Added pt-24 for spacing below Navigation */}
                <h1 className="text-3xl font-bold mb-4">Work Not Found</h1>
                <p>We could not find any project named <strong>{workName}</strong>.</p>
            </div>
        );
    }

    // Make sure you have at least 4 images to display
    // If your data sometimes has fewer images, you might need extra checks
    const [img1, img2, img3, img4] = project.projectImages;

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <ParticlesBackground id="tsparticles" />
            <div className="bg-white">
                <Navigation />
                <div className="mx-auto max-w-2xl px-4 py-8 pt-28 sm:pt-36 mt-8 lg:mt-0">
                    {/* Work Type */}
                    <p className="text-sm text-vivid-cyan font-unbounded font-bold leading-9">
                        {project.workType}
                    </p>

                    {/* Project Name */}
                    <h1 className="text-4xl font-bold font-unbounded text-gray-800 mb-6">
                        {project.name}
                    </h1>

                    {/* Image Layout */}
                    <div className="space-y-6">
                        {/* 1) First Big Image */}
                        {img1 && (
                            <img
                                src={img1}
                                alt={`${project.name} screenshot 1`}
                                className=""
                            />
                        )}

                        {/* 2) Second Big Image */}
                        {img2 && (
                            <img
                                src={img2}
                                alt={`${project.name} screenshot 2`}
                                className=""
                            />
                        )}

                        {/* 3) Two Smaller Images, side-by-side */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {img3 && (
                                <img
                                    src={img3}
                                    alt={`${project.name} screenshot 3`}
                                    className=""
                                />
                            )}
                            {img4 && (
                                <img
                                    src={img4}
                                    alt={`${project.name} screenshot 4`}
                                    className=""
                                />
                            )}
                        </div>
                    </div>

                    {/* Subheading, Description & CTA */}
                    <section className="mt-10 mb-12">
                        <h2 className="text-2xl font-semibold font-unbounded text-gray-800 mb-4">
                            {project.subheading}
                        </h2>
                        <p className="text-md text-gray-700 font-poppins whitespace-pre-line mb-6">
                            {project.description}
                        </p>
                        <div className="">
                            {project.name === "Monet.ai" ? (
                                <div
                                    onClick={() => {
                                        // Change: Pass the whole project instead of project.name
                                        setSelectedWork(project);
                                        setOpen(true);
                                    }}
                                >
                                    <AwesomeButton type="primary" ripple>
                                        {project.cta || "Explore Work"}
                                        <ArrowLongRightIcon className="mx-1 h-auto w-7 text-gray-400" aria-hidden="true" />
                                    </AwesomeButton>
                                </div>
                            ) : (
                                <Link to={project.href} target="_blank" rel="noopener noreferrer">
                                    <AwesomeButton type="primary" ripple>
                                        {project.cta || "Explore Work"}
                                        <ArrowLongRightIcon className="mx-1 h-auto w-7 text-gray-400" aria-hidden="true" />
                                    </AwesomeButton>
                                </Link>
                            )}
                        </div>
                    </section>

                    {/* Project Scope & Software (two-column layout) */}
                    <section className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-12">
                        {/* Project Scope */}
                        <div>
                            <h3 className="text-xl font-semibold font-unbounded text-gray-800 mb-3">
                                Project Scope:
                            </h3>
                            <ul className="text-md list-disc list-inside font-poppins text-gray-700 space-y-1 mb-8">
                                {project.projectTimeline.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                            <ul className="text-md list-disc list-inside font-poppins text-gray-700 space-y-1">
                                {project.projectScope.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>

                        {/* Software */}
                        <div>
                            <h3 className="text-xl font-semibold font-unbounded text-gray-800 mb-3">
                                Software:
                            </h3>
                            <ul className="text-md list-disc list-inside font-poppins text-gray-700 space-y-1">
                                {project.software.map((tool, index) => (
                                    <li key={index}>{tool}</li>
                                ))}
                            </ul>
                        </div>
                    </section>
                </div>

                <MonetModal
                    open={selectedWork?.name === 'Monet.ai'}
                    setOpen={setSelectedWork}
                    email={email}
                    setEmail={setEmail}
                    handleEmail={handleEmail}
                />
            </div>
            <FooterCentered />
            <div className="mb-32"></div>
        </motion.div>
    );
}